<template>
    <v-container fluid>
        <v-card outlined class="mb-5">
            <v-card-title class="clickable pa-1" @click="$store.set('filters@show', !show)">
                <v-row no-gutters align="center">
                    <v-col cols="auto">
                        <v-fab-transition hide-on-leave>
                            <v-btn icon aria-label="Show filters" v-if="!show">
                                <v-icon color="primary">mdi-plus</v-icon>
                            </v-btn>
                        </v-fab-transition>
                        <v-fab-transition hide-on-leave>
                            <v-btn icon aria-label="Hide filters" v-if="show">
                                <v-icon color="primary">mdi-minus</v-icon>
                            </v-btn>
                        </v-fab-transition>
                        <span class="title primary--text mr-3">Search Filters</span>
                    </v-col>
                    <v-col>
                        <span>
                            <v-chip small label close @click:close="close('text')" class="mr-1" color="primary" v-if="text && text.length">{{ text }}</v-chip>
                        </span>
                        <span>
                            <v-chip small label close @click:close="close('selectedAudiencesIndexes', index)" class="mr-1" color="primary" v-for="(selectedAudience, index) in selectedAudiences" :key="index">
                                {{ selectedAudience }}
                            </v-chip>
                        </span>
                        <span>
                            <v-chip small label close @click:close="close('selectedFocusesIndexes', index)" class="mr-1" color="primary" v-for="(selectedFocus, index) in selectedFocuses" :key="index">
                                {{ selectedFocus }}
                            </v-chip>
                        </span>
                        <span>
                            <v-chip small label close @click:close="close('selectedStatesIndexes', index)" class="mr-1" color="primary" v-for="(selectedState, index) in selectedStates" :key="index">
                                {{ selectedState }}
                            </v-chip>
                        </span>
                        <span>
                            <v-chip small label close @click:close="close('selectedCategoriesIndexes', index)" class="mr-1" color="primary" v-for="(selectedCategory, index) in selectedCategories" :key="index">
                                {{ selectedCategory }}
                            </v-chip>
                        </span>
                    </v-col>
                    <v-col cols="auto" class="text-right">
                        <span class="subtitle-1 ml-3" v-if="hasActiveFilters">
                            <a @click.stop="resetAll()">Reset all filters</a>
                        </span>
                        <v-progress-circular size="20" width="2" indeterminate color="primary" :class="{ hidden: !loading }" class="mx-3"></v-progress-circular>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-slide-y-transition leave-absolute>
                <div v-if="show">
                    <v-divider></v-divider>
                    <v-row no-gutters align="start" class="ma-3 mb-n2">
                        <v-col cols="6" class="offset-3">
                            <v-text-field
                                v-model="text"
                                @input="searchDebounced()"
                                prepend-inner-icon="mdi-magnify"
                                label="Enter keywords"
                                clearable
                                outlined
                                dense
                                persistent-hint
                                hint='Use double quotes to search for an exact match ("industrial automation")'
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="text-right">
                            <a class="primary--text body-2 ml-3" @click="$root.$definitionsDialog.show()">
                                <span>Help with definitions</span>
                                <v-icon size="20" color="primary" class="ml-1">mdi-help-circle-outline</v-icon>
                            </a>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center" class="mx-12">
                        <v-col class="ml-12">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="auto" class="mx-6">
                            <span class="body-2 primary--text">and/or</span>
                        </v-col>
                        <v-col class="mr-12">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-card-text class="pt-0">
                        <v-row>
                            <v-col cols="3" class="pb-0">
                                <div class="title text-uppercase primary--text">Audience</div>
                                <v-list dense flat class="filter-list overflow-y-auto">
                                    <v-list-item class="pa-0" :class="{ 'v-list-item--active': !selectedAudiencesIndexes.length }" @click="all('selectedAudiencesIndexes')">
                                        <v-list-item-action class="my-0 mr-2">
                                            <v-icon :color="selectedAudiencesIndexes.length ? '' : 'primary'">{{ selectedAudiencesIndexes.length ? 'mdi-radiobox-blank' : 'mdi-radiobox-marked' }}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>All</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item-group title="Audience" v-model="selectedAudiencesIndexes" @change="searchDebounced()" multiple>
                                        <v-list-item role="option" v-for="(audience, index) in audiences" :key="index" class="pa-0">
                                            <template v-slot:default="{ active }">
                                                <v-list-item-action class="my-0 mr-2">
                                                    <v-icon :color="active ? 'primary' : ''">{{ active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ audience }}</v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                            <v-col cols="3" class="pb-0">
                                <div class="title text-uppercase primary--text">Technology Focus Area</div>
                                <v-list dense flat class="filter-list overflow-y-auto">
                                    <v-list-item class="pa-0" :class="{ 'v-list-item--active': !selectedFocusesIndexes.length }" @click="all('selectedFocusesIndexes')">
                                        <v-list-item-action class="my-0 mr-2">
                                            <v-icon :color="selectedFocusesIndexes.length ? '' : 'primary'">{{ selectedFocusesIndexes.length ? 'mdi-radiobox-blank' : 'mdi-radiobox-marked' }}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>All</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item-group title="Technology Focus Area" v-model="selectedFocusesIndexes" @change="searchDebounced()" multiple>
                                        <v-list-item role="option" v-for="(focus, index) in focuses" :key="index" class="pa-0">
                                            <template v-slot:default="{ active }">
                                                <v-list-item-action class="my-0 mr-2">
                                                    <v-icon :color="active ? 'primary' : ''">{{ active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ focus }}</v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                            <v-col cols="3" class="pb-0">
                                <div class="title text-uppercase primary--text">State</div>
                                <v-list dense flat class="filter-list overflow-y-auto">
                                    <v-list-item class="pa-0" :class="{ 'v-list-item--active': !selectedStatesIndexes.length }" @click="all('selectedStatesIndexes')">
                                        <v-list-item-action class="my-0 mr-2">
                                            <v-icon :color="selectedStatesIndexes.length ? '' : 'primary'">{{ selectedStatesIndexes.length ? 'mdi-radiobox-blank' : 'mdi-radiobox-marked' }}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>All</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item-group title="State" v-model="selectedStatesIndexes" @change="searchDebounced()" multiple>
                                        <v-list-item role="option" v-for="(state, index) in states" :key="index" class="pa-0">
                                            <template v-slot:default="{ active }">
                                                <v-list-item-action class="my-0 mr-2">
                                                    <v-icon :color="active ? 'primary' : ''">{{ active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ state.name }}</v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                            <v-col cols="3" class="pb-0">
                                <div class="title text-uppercase primary--text">Category</div>
                                <v-list dense flat class="filter-list overflow-y-auto">
                                    <v-list-item class="pa-0" :class="{ 'v-list-item--active': !selectedCategoriesIndexes.length }" @click="all('selectedCategoriesIndexes')">
                                        <v-list-item-action class="my-0 mr-2">
                                            <v-icon :color="selectedCategoriesIndexes.length ? '' : 'primary'">{{ selectedCategoriesIndexes.length ? 'mdi-radiobox-blank' : 'mdi-radiobox-marked' }}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>All</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item-group title="Category" v-model="selectedCategoriesIndexes" @change="searchDebounced()" multiple>
                                        <v-list-item role="option" v-for="(category, index) in categories" :key="index" class="pa-0">
                                            <template v-slot:default="{ active }">
                                                <v-list-item-action class="my-0 mr-2">
                                                    <v-icon :color="active ? 'primary' : ''">{{ active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ category }}</v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </div>
            </v-slide-y-transition>
        </v-card>
        <v-card outlined>
            <v-toolbar dense flat dark color="primary">
                <v-toolbar-title class="title text-uppercase font-weight-bold">Results ({{ programs.length }}) </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn text dark @click="resetMapViewport()"><v-icon left>mdi-map</v-icon>Reset Map View</v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <GmapMap ref="map" :center="defaultCenter" :zoom="defaultZoom" class="map">
                    <GmapCluster :zoom-on-click="true" :grid-size="clusterGridSize">
                        <GmapMarker
                            v-for="(location, index) in locations"
                            :key="index"
                            :position="location.latLng"
                            :label="{ text: `${location.count}`, fontSize: '11px', fontWeight: 'bold' }"
                            :title="`${location.name}`"
                            :clickable="true"
                            :icon="{ url: `http://maps.google.com/mapfiles/ms/micons/${location.hasPotentialPartners ? 'green' : 'red'}.png`, labelOrigin: new google.maps.Point(16, 11) }"
                            @click="selectedLocation = location"
                            @mouseover="statusText = location.full"
                            @mouseout="statusText = null"
                        ></GmapMarker>
                    </GmapCluster>
                    <template #visible>
                        <v-fade-transition>
                            <div class="status-text" v-if="!!statusText">{{ statusText }}</div>
                        </v-fade-transition>
                    </template>
                </GmapMap>
                <div>
                    <v-divider class="my-4"></v-divider>
                    <div>
                        <span class="body-1 mr-2">Selected Service Location:</span>
                        <v-chip small label close @click:close="close('selectedLocation')" class="mr-1" color="primary" v-if="selectedLocation">{{ selectedLocation.full }}</v-chip>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <SearchResultsTable :programs="filteredPrograms"></SearchResultsTable>
    </v-container>
</template>

<script>
    import { get, sync } from 'vuex-pathify';
    import { gmapApi } from 'vue2-google-maps';
    import { debounce } from 'lodash';
    import SearchResultsTable from '@/components/SearchResultsTable';
    import { audiences, focuses, states, categories } from '@/common';

    const DEFAULT_CLUSTER_GRID_SIZE = 10;
    const DEFAULT_CENTER = { lat: 39.8282, lng: -98.5795 };
    const DEFAULT_ZOOM = 4;
    const MAX_ZOOM = 7;

    export default {
        name: 'Search',
        components: { SearchResultsTable },
        data: () => ({
            audiences,
            focuses,
            states,
            categories,
            statusText: null,
            clusterGridSize: DEFAULT_CLUSTER_GRID_SIZE,
            defaultCenter: DEFAULT_CENTER,
            defaultZoom: DEFAULT_ZOOM
        }),
        computed: {
            google: gmapApi,
            bounds() {
                if (this.google && this.locations && this.locations.length) {
                    const bounds = new this.google.maps.LatLngBounds();
                    for (let location of this.locations) {
                        if (location.latLng) {
                            bounds.extend(location.latLng);
                        }
                    }
                    return bounds;
                }
                return null;
            },
            hasActiveFilters: get('hasActiveFilters'),
            loading: get('loading'),
            locations: get('locations'),
            programs: get('programs'),
            filteredPrograms: get('filteredPrograms'),
            ...sync('filters@*'),
            ...get('selected*')
        },
        methods: {
            close(prop, index) {
                if ('text' === prop) {
                    this.$store.set('filters@text', '');
                } else if ('selectedLocation' === prop) {
                    this.$store.set('filters@selectedLocation', '');
                    return; // skip calling search
                } else {
                    const arr = [...this[prop]];
                    arr.splice(index, 1);
                    this.$store.set(`filters@${prop}`, arr);
                }
                this.searchDebounced();
            },
            all(prop) {
                this.$store.set(`filters@${prop}`, []);
                this.searchDebounced();
            },
            resetAll() {
                this.$store.commit('resetFilters');
                this.searchDebounced();
            },
            resetMapViewport() {
                if (this.$refs.map && this.$refs.map.$mapObject) {
                    if (this.bounds) {
                        this.$refs.map.fitBounds(this.bounds);
                        if (this.$refs.map.$mapObject.getZoom() > MAX_ZOOM) {
                            this.$refs.map.$mapObject.setZoom(MAX_ZOOM);
                        }
                    } else {
                        this.$refs.map.$mapObject.setCenter(DEFAULT_CENTER);
                        this.$refs.map.$mapObject.setZoom(DEFAULT_ZOOM);
                    }
                }
            },
            async search() {
                await this.$store.dispatch('search');
                this.resetMapViewport();
            },
            searchDebounced: debounce(function () {
                this.search();
            }, 500)
        },
        mounted() {
            this.search();
        }
    };
</script>

<style lang="scss" scoped>
    ::v-deep .v-list--dense .v-list-item {
        min-height: 0;
    }
    ::v-deep .v-list-item {
        opacity: 0.75;
    }
    ::v-deep .v-list-item--active {
        opacity: 1;
    }

    .text-truncate {
        max-width: 90%;
    }

    .clickable {
        cursor: pointer;
    }
    .hidden {
        visibility: hidden;
    }

    .filter-list {
        max-height: 350px;
    }

    .map {
        width: 100%;
        height: 480px;
        .status-text {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 100;
            padding: 2px 6px;
            border-radius: 4px;
            font-size: 85%;
            color: #ffffff;
            background-color: var(--v-primary-base);
        }
    }
</style>

<template>
    <v-container>
        <v-row justify="center">
            <v-col sm="8" lg="6">
                <v-card flat>
                    <v-form @submit.prevent="submit">
                        <v-card-text v-if="'login' === mode">
                            <v-text-field outlined hide-details class="mt-0" prepend-inner-icon="mdi-account" v-model="username" ref="username" label="Username"></v-text-field>
                            <v-text-field outlined hide-details class="mt-2" prepend-inner-icon="mdi-lock" v-model="password" ref="password" label="Password" type="password" aria-autocomplete="none" autocomplete="off"></v-text-field>
                        </v-card-text>
                        <v-card-text v-if="'code' === mode">
                            <div class="primary--text text-body-1">Enter the verification code that was sent to you via text message</div>
                            <v-text-field outlined hide-details class="mt-2" prepend-inner-icon="mdi-key" v-model="code" ref="code" label="Code" aria-autocomplete="none" autocomplete="off">
                                <template v-slot:append>
                                    <v-btn text small color="primary" @click="resendCode()">
                                        <v-icon left>mdi-refresh</v-icon>
                                        Resend Code
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-card-text>
                        <v-card-text v-if="'forgot' === mode">
                            <div class="primary--text text-body-1 text-center">Enter the email address associated with your account</div>
                            <v-text-field outlined hide-details class="mt-2" prepend-inner-icon="mdi-email" v-model="email" ref="email" label="Email"></v-text-field>
                        </v-card-text>
                        <v-card-text v-if="'reset' === mode">
                            <div class="primary--text text-body-1 text-center">Check your email for login instructions</div>
                        </v-card-text>
                        <v-card-actions class="mx-2">
                            <v-btn color="primary" type="submit" v-if="'forgot' === mode">
                                <v-icon left>mdi-refresh</v-icon>
                                Submit
                            </v-btn>
                            <v-btn color="primary" type="submit" v-if="'login' === mode">
                                <v-icon left>mdi-login</v-icon>
                                Login
                            </v-btn>
                            <v-btn color="primary" type="submit" v-if="'code' === mode">
                                <v-icon left>mdi-check</v-icon>
                                Verify Code
                            </v-btn>
                            <span class="px-2 error--text">{{ error }}</span>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="mode = 'forgot'" v-if="'login' === mode">
                                Forgot Login
                                <v-icon x-small>mdi-help</v-icon>
                            </v-btn>
                            <v-btn color="primary" text @click="mode = 'login'" v-else>
                                <v-icon small left>mdi-arrow-left</v-icon>
                                Back to login
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <v-card outlined class="mt-6">
            <v-card-text class="text-caption">
                You are accessing a U.S. Government information system, which includes: 1) this computer, 2) this computer network, 3) all Government-furnished computers connected to this network, and 4) all Government-furnished devices and
                storage media attached to this network or to a computer on this network. You understand and consent to the following: you may access this information system for authorized use only; unauthorized use of the system is
                prohibited and subject to criminal and civil penalties; you have no reasonable expectation of privacy regarding any communication or data transiting or stored on this information system at any time and for any lawful
                Government purpose, the Government may monitor, intercept, audit, and search and seize any communication or data transiting or stored on this information system; and any communications or data transiting or stored on this
                information system may be disclosed or used for any lawful Government purpose. This information system may contain Controlled Unclassified Information (CUI) that is subject to safeguarding or dissemination controls in
                accordance with law, regulation, or Government-wide policy. Accessing and using this system indicates your understanding of this warning.
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            username: '',
            password: '',
            email: '',
            code: '',
            error: '',
            mode: 'login'
        }),
        methods: {
            submit() {
                this.error = '';
                if ('forgot' === this.mode) {
                    this.$api.post('/forgot', { email: this.email, url: window.location.origin }).finally(() => {
                        this.mode = 'reset';
                        this.username = '';
                        this.password = '';
                        this.email = '';
                        this.code = '';
                    });
                } else {
                    this.$api
                        .post('/login', { username: this.username, password: this.password, code: this.code })
                        .then(async () => this.$store.dispatch('login'))
                        .catch((error) => {
                            if (/MFA/.test(error.response.data)) {
                                this.code = '';
                                this.mode = 'code';
                                setTimeout(() => {
                                    this.$refs.code.focus();
                                }, 100);
                            } else {
                                this.error = error.response.data;
                                setTimeout(() => {
                                    this.error = '';
                                }, 5000);
                            }
                        });
                }
            },
            resendCode() {
                this.code = '';
                this.submit();
            }
        }
    };
</script>

<template>
    <v-fade-transition hide-on-leave>
        <Login v-if="!user"></Login>
        <Search v-if="user && 'search' === view"></Search>
        <Admin v-if="user && 'admin' === view"></Admin>
        <Users v-if="user && user.isAdmin && 'users' === view"></Users>
    </v-fade-transition>
</template>

<script>
    import Admin from '@/components/Admin';
    import Login from '@/components/Login';
    import Search from '@/components/Search';
    import Users from '@/components/Users';
    import { get } from 'vuex-pathify';

    export default {
        name: 'Home',
        components: { Admin, Login, Search, Users },
        computed: {
            view: get('view'),
            user: get('user')
        }
    };
</script>

<template>
    <v-container fluid>
        <div v-if="users && users.length">
            <v-data-table dense :headers="headers" :items="users" item-key="_id" disable-pagination hide-default-footer>
                <!-- eslint-disable-next-line -->
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="addDialog" persistent width="480px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" v-bind="attrs" v-on="on">
                                    <v-icon left>mdi-plus</v-icon>
                                    Add User
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="pt-6">
                                    <v-text-field dense outlined hide-details class="mb-2" v-model="addUser.username" label="Username"></v-text-field>
                                    <v-text-field dense outlined hide-details class="mb-2" v-model="addUser.email" label="Email"></v-text-field>
                                    <v-text-field dense outlined hide-details class="mb-2" v-model="addUser.phone" label="Phone"></v-text-field>
                                    <v-text-field dense outlined hide-details class="mb-2" v-model="addUser.name" label="Name"></v-text-field>
                                    <v-select dense outlined hide-details v-model="addUser.roles" placeholder="Role" :items="roles" :item-text="(item) => item.label" :item-value="(item) => item.value"></v-select>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn text @click="addUserCancel()">Cancel</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="addUserSave()" :disabled="!addUser.username || !addUser.email || !addUser.phone || !addUser.name || !addUser.roles">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.username="{ item }">
                    <v-edit-dialog :return-value.sync="item.username" large @save="save(item)">
                        {{ item.username }}
                        <template v-slot:input>
                            <v-text-field v-model="item.username" label="Username" single-line></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.email="{ item }">
                    <v-edit-dialog :return-value.sync="item.email" large @save="save(item)">
                        {{ item.email }}
                        <template v-slot:input>
                            <v-text-field v-model="item.email" label="Email" single-line></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.phone="{ item }">
                    <v-edit-dialog :return-value.sync="item.phone" large @save="save(item)">
                        {{ item.phone }}
                        <template v-slot:input>
                            <v-text-field v-model="item.phone" label="Phone" single-line></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.name="{ item }">
                    <v-edit-dialog :return-value.sync="item.name" large @save="save(item)">
                        {{ item.name }}
                        <template v-slot:input>
                            <v-text-field v-model="item.name" label="Name" single-line></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.roles="{ item }">
                    <v-select
                        dense
                        outlined
                        hide-details
                        v-model="item.roles"
                        @change="save(item)"
                        placeholder="Role"
                        :items="roles"
                        :item-text="(item) => item.label"
                        :item-value="(item) => item.value"
                        :disabled="item._id === user._id"
                    ></v-select>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.enabled="{ item }">
                    <v-switch dense inset hide-details class="mt-0" v-model="item.enabled" @change="save(item)" :disabled="item._id === user._id"></v-switch>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <div class="text-no-wrap text-right">
                        <v-btn icon aria-label="Delete" @click.stop="del(item)" :disabled="item._id === user._id">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table>
        </div>
        <ConfirmDialog ref="confirm"></ConfirmDialog>
    </v-container>
</template>

<script>
    import ConfirmDialog from '@/components/ConfirmDialog';
    import { institutes } from '@/common';
    import { get } from 'vuex-pathify';

    export default {
        components: { ConfirmDialog },
        data: () => ({
            addDialog: false,
            addUser: {},
            headers: [
                { text: 'Username', value: 'username' },
                { text: 'Email', value: 'email' },
                { text: 'Phone', value: 'phone' },
                { text: 'Name', value: 'name' },
                { text: 'Roles', value: 'roles' },
                { text: 'Enabled', value: 'enabled' },
                { text: '', value: 'actions', sortable: false }
            ],
            users: null
        }),
        computed: {
            roles: () => [{ label: 'admin', value: ['admin'] }].concat(institutes.filter((institute) => 'Potential Partner' !== institute).map((institute) => ({ label: institute, value: [`edit:${institute}`] }))),
            user: get('user')
        },
        mounted() {
            this.search();
        },
        methods: {
            handleError(error) {
                if (401 === error.response.status) {
                    this.$refs.confirm.show('Session Timeout', 'Your session has timed out, please log in again', { showCancel: false }).then(() => {
                        window.location.reload();
                    });
                } else if (403 === error.response.status) {
                    this.$refs.confirm.show('Permission denied', 'You do not have permission to perform this operation', { showCancel: false });
                } else {
                    this.$refs.confirm.show('Error', 'An unexpected error occurred, please try again', { showCancel: false });
                }
            },
            search() {
                return this.$api.get('/users/search').then((response) => {
                    this.users = response.data;
                });
            },
            del(user) {
                this.$refs.confirm.show('Delete Confirmation', `Are you sure you want to delete this user?`).then((confirm) => {
                    if (confirm) {
                        this.$api
                            .delete(`/users/delete/${user._id}`)
                            .then(() => {
                                this.search();
                            })
                            .catch(this.handleError);
                    }
                });
            },
            save(user) {
                this.$api
                    .post('/users/save', Object.assign({}, user, { url: window.location.origin }))
                    .then((response) => {
                        const index = this.users.findIndex((u) => user._id === u._id);
                        if (index > -1) {
                            this.users.splice(index, 1, response.data);
                        } else {
                            this.search();
                        }
                    })
                    .catch(this.handleError);
            },
            addUserCancel() {
                this.addDialog = false;
                this.addUser = {};
            },
            addUserSave() {
                this.save(this.addUser);
                this.addUserCancel();
            }
        }
    };
</script>

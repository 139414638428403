<template>
    <v-container fluid class="pa-0">
        <v-container fluid>
            <div>
                <v-text-field v-model="text" @input="searchDebounced()" :messages="results" prepend-inner-icon="mdi-magnify" label="Search" clearable filled rounded>
                    <template v-slot:message="msg">
                        <div class="text-right">{{ msg.message }}</div>
                    </template>
                    <template v-slot:append-outer>
                        <v-btn color="primary" @click="add()" class="append-outer-button"><v-icon left>mdi-plus</v-icon>Add Initiative</v-btn>
                    </template>
                </v-text-field>
            </div>
            <div v-if="programs && programs.length">
                <v-data-table dense :headers="headers" :items="programs" item-key="_id" disable-pagination hide-default-footer @click:row="load" class="clickable-rows">
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.shortLocations="{ item }">
                        <div v-for="(location, index) in item.shortLocations" :key="index">{{ location }}</div>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.actions="{ item }">
                        <div class="text-no-wrap text-right">
                            <v-btn icon aria-label="Delete" @click.stop="del(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </div>
        </v-container>
        <v-dialog v-model="locationsDialog" width="100%" scrollable persistent role="dialog">
            <v-card role="region">
                <v-toolbar dark color="primary" role="toolbar">
                    <v-btn icon dark aria-label="Close" @click="locationsDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Add/Edit Locations</v-toolbar-title>
                </v-toolbar>
                <v-container fluid>
                    <div>
                        <v-text-field dense v-model="locationsText" @input="searchLocationsDebounced()" :messages="resultsLocations" prepend-inner-icon="mdi-magnify" label="Search" clearable filled rounded>
                            <template v-slot:message="msg">
                                <div class="text-right">{{ msg.message }}</div>
                            </template>
                            <template v-slot:append-outer>
                                <v-btn color="primary" @click="locations.unshift({ edit: true })" :disabled="!!locations.length && !locations[0]._id" class="append-outer-button"><v-icon left>mdi-plus</v-icon>Add Location</v-btn>
                            </template>
                        </v-text-field>
                    </div>
                </v-container>
                <v-card-text>
                    <v-container class="pa-0" fluid>
                        <v-data-table dense :headers="locationsHeaders" :items="locations" item-key="_id" :item-class="(item) => (hasLocation(item) ? 'active' : '')" disable-pagination hide-default-footer>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.add="{ item }">
                                <div class="text-no-wrap">
                                    <v-btn icon aria-label="Add" @click.stop="program.locations.push(item)" :disabled="item.edit || hasLocation(item)">
                                        <v-icon>mdi-plus-box</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.name="{ item }">
                                <v-text-field dense hide-details v-model="item.name" placeholder="Name" outlined v-if="item.edit"></v-text-field>
                                <div v-else>{{ item.name }}</div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.address="{ item }">
                                <v-text-field dense hide-details v-model="item.address" placeholder="Street Address" outlined v-if="item.edit"></v-text-field>
                                <div class="text-no-wrap" v-else>{{ item.address }}</div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.city="{ item }">
                                <v-text-field dense hide-details v-model="item.city" placeholder="City" outlined v-if="item.edit"></v-text-field>
                                <div class="text-no-wrap" v-else>{{ item.city }}</div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.state="{ item }">
                                <v-select dense hide-details v-model="item.state" placeholder="State" :items="states" :item-text="(item) => item.name" :item-value="(item) => item.code" outlined v-if="item.edit"></v-select>
                                <div class="text-no-wrap" v-else>{{ item.state }}</div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.zip="{ item }">
                                <v-text-field dense hide-details v-model="item.zip" placeholder="Zip" outlined v-if="item.edit" :maxlength="5"></v-text-field>
                                <div class="text-no-wrap" v-else>{{ item.zip }}</div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.actions="{ item, index }">
                                <div class="text-no-wrap text-right">
                                    <v-btn
                                        icon
                                        aria-label="Map"
                                        :href="`https://www.google.com/maps/search/?api=1&query=${item.location.coordinates[1]},${item.location.coordinates[0]}`"
                                        target="_blank"
                                        v-if="item.location && item.location.coordinates && 2 === item.location.coordinates.length"
                                    >
                                        <v-icon>mdi-map-marker</v-icon>
                                    </v-btn>
                                    <v-btn icon aria-label="Map" :disabled="true" v-else>
                                        <v-icon>mdi-map-marker-off</v-icon>
                                    </v-btn>
                                    <v-btn icon aria-label="Edit" @click.stop="item.edit = true" :disabled="item.edit">
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                    <v-btn icon aria-label="Save" @click.stop="saveLocation(item, index)" :disabled="!item.edit || !locationValid(item)">
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                    <v-btn icon aria-label="Delete" @click.stop="delLocation(item, index)" :disabled="!!item._id && item.programs > 0">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-if="program" v-model="program" fullscreen hide-overlay persistent transition="dialog-bottom-transition" role="dialog">
            <v-card tile role="region">
                <v-toolbar dark color="primary" role="toolbar">
                    <v-row justify="space-between" align="center">
                        <v-col>
                            <div class="d-flex align-center">
                                <v-btn icon dark aria-label="Close" @click="close()" :disabled="modified">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title class="mx-3">{{ program._id ? 'Edit' : 'Add' }} Initiative</v-toolbar-title>
                            </div>
                        </v-col>
                        <v-col cols="auto">
                            <span class="text-caption" v-if="program.updatedAt"><em>Last Updated:</em> {{ program.updatedAt | date('LLL') }}</span>
                        </v-col>
                        <v-col class="text-right">
                            <span class="text-caption mx-3" v-if="!valid">Missing required* field(s)</span>
                            <v-btn text dark @click="save()" :disabled="!modified || !valid"><v-icon left>mdi-content-save</v-icon>Save</v-btn>
                            <v-btn text dark @click="undo()" :disabled="!modified"><v-icon left>mdi-undo</v-icon>Cancel</v-btn>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-container fluid>
                    <v-row class="align-center">
                        <v-col cols="9">
                            <v-text-field dense hide-details v-model="program.name" label="Name / Title*" outlined></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <div class="text-right">
                                <a class="primary--text body-2" @click="$root.$definitionsDialog.show()">
                                    <span>Help with definitions</span>
                                    <v-icon size="20" color="primary" class="ml-1">mdi-help-circle-outline</v-icon>
                                </a>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-card class="mb-3">
                                <v-toolbar dark dense color="primary" role="group">
                                    <v-toolbar-title>Category*</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-radio-group dense hide-details v-model="program.category">
                                        <v-radio v-for="(category, index) in categories" :key="index" :label="category" :value="category"></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                            </v-card>
                            <v-card class="mb-3">
                                <v-toolbar dark dense color="primary" role="group">
                                    <v-toolbar-title>Audience</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <div v-for="(audience, index) in audiences" :key="index">
                                        <v-checkbox dense hide-details v-model="program.audience" :label="audience" :value="audience"></v-checkbox>
                                    </div>
                                </v-card-text>
                            </v-card>
                            <v-card class="mb-3">
                                <v-toolbar dark dense color="primary" role="group">
                                    <v-toolbar-title>Format</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <div v-for="(format, index) in formats" :key="index">
                                        <v-checkbox dense hide-details v-model="program.format" :label="format" :value="format"></v-checkbox>
                                    </div>
                                </v-card-text>
                            </v-card>
                            <v-card class="mb-3">
                                <v-toolbar dark dense color="primary" role="group">
                                    <v-toolbar-title>Status</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-radio-group dense hide-details v-model="program.status">
                                        <v-radio v-for="(status, index) in statuses" :key="index" :label="status" :value="status"></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col cols="6">
                                    <v-card class="mb-3 pb-3">
                                        <v-toolbar dark dense color="primary" role="group">
                                            <v-toolbar-title>Institute*</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <span class="text-caption">{{ program.institute.length }} of {{ institutes.length }} selected</span>
                                        </v-toolbar>
                                        <v-card-text class="filter-list overflow-y-auto pt-3 pb-0">
                                            <div v-for="(institute, index) in institutes" :key="index">
                                                <v-checkbox dense hide-details v-model="program.institute" :label="institute.text" :value="institute.value" :disabled="institute.disabled"></v-checkbox>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="6">
                                    <v-card class="mb-3 pb-3">
                                        <v-toolbar dark dense color="primary" role="group">
                                            <v-toolbar-title>Technology Focus Area*</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <span class="text-caption">{{ program.focus.length }} of {{ focuses.length }} selected</span>
                                        </v-toolbar>
                                        <v-card-text class="filter-list overflow-y-auto pt-3 pb-0">
                                            <div v-for="(focus, index) in focuses" :key="index">
                                                <v-checkbox dense hide-details v-model="program.focus" :label="focus" :value="focus"></v-checkbox>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <div class="mb-6">
                                <div class="text-caption">Locations*</div>
                                <v-row no-gutters class="mb-2 align-center" v-for="(location, index) in program.locations" :key="index">
                                    <v-col cols="auto">
                                        <v-btn icon aria-label="Delete" @click="program.locations.splice(index, 1)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col class="mr-1">
                                        {{ program.locations[index].full }}
                                    </v-col>
                                </v-row>
                                <v-btn small color="primary" @click="editLocations()">
                                    <v-icon left>mdi-plus</v-icon>
                                    Add/Edit Locations
                                </v-btn>
                            </div>
                            <div class="mb-6">
                                <div class="text-caption">Partners</div>
                                <v-row no-gutters class="mb-2 align-center" v-for="(partner, index) in program.partners" :key="index">
                                    <v-col cols="auto">
                                        <v-btn icon aria-label="Delete" @click="program.partners.splice(index, 1)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col class="mr-1">
                                        <v-text-field dense hide-details v-model="program.partners[index]" placeholder="Partner" outlined></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-btn small color="primary" @click="program.partners.push('')">
                                    <v-icon left>mdi-plus</v-icon>
                                    Add Partner
                                </v-btn>
                            </div>
                            <v-textarea dense auto-grow v-model="program.description" label="Description*" outlined></v-textarea>
                            <v-text-field dense v-model="program.url" label="URL*" outlined></v-text-field>
                            <div class="mb-6">
                                <div class="text-caption">Funding Sources</div>
                                <v-row no-gutters class="mb-2 align-center" v-for="(fund, index) in program.funding" :key="index">
                                    <v-col cols="auto">
                                        <v-btn icon aria-label="Delete" @click="program.funding.splice(index, 1)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col class="mr-1">
                                        <v-text-field dense hide-details v-model="program.funding[index]" placeholder="Funding Source" outlined></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-btn small color="primary" @click="program.funding.push('')">
                                    <v-icon left>mdi-plus</v-icon>
                                    Add Funding Source
                                </v-btn>
                            </div>
                            <v-textarea dense auto-grow v-model="program.note" label="Admin Notes" outlined></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <ConfirmDialog ref="confirm"></ConfirmDialog>
    </v-container>
</template>

<script>
    import { get } from 'vuex-pathify';
    import ConfirmDialog from '@/components/ConfirmDialog';
    import { audiences, categories, formats, statuses, institutes, focuses, states } from '@/common';
    import { cloneDeep, debounce, filter, find, includes, map } from 'lodash';

    export default {
        components: { ConfirmDialog },
        data: () => ({
            headers: [
                { text: 'Institute', value: 'institute' },
                { text: 'Name / Title', value: 'name' },
                { text: 'Locations', value: 'shortLocations' },
                { text: 'Category', value: 'category' },
                { text: '', value: 'actions', sortable: false }
            ],
            locationsHeaders: [
                { text: '', value: 'add', sortable: false },
                { text: 'Name*', value: 'name' },
                { text: 'Street Address*', value: 'address' },
                { text: 'City*', value: 'city' },
                { text: 'State*', value: 'state' },
                { text: 'Zip*', value: 'zip' },
                { text: 'Initiatives', value: 'programs' },
                { text: '', value: 'actions', sortable: false }
            ],
            locationsDialog: false,
            locationsText: '',
            locations: [],
            text: '',
            audiences,
            categories,
            formats,
            statuses,
            focuses,
            states,
            programs: [],
            cleanProgram: null,
            program: null
        }),
        computed: {
            institutes() {
                return map(institutes, (institute) => {
                    return {
                        text: institute,
                        value: institute,
                        disabled: '*' === this.userInstitutes ? false : includes(this.userInstitutes, institute)
                    };
                });
            },
            userInstitutes: get('userInstitutes'),
            resultsLocations() {
                return `${this.locations.length} ${1 === this.locations.length ? 'entry' : 'entries'} found`;
            },
            results() {
                return `${this.programs.length} ${1 === this.programs.length ? 'entry' : 'entries'} found`;
            },
            valid() {
                return this.program.name && this.program.category && this.program.institute?.length && this.program.focus?.length && this.program.locations?.length && this.program.description && this.program.url;
            },
            modified() {
                return JSON.stringify(this.cleanProgram) !== JSON.stringify(this.program);
            }
        },
        mounted() {
            this.search();
        },
        methods: {
            handleError(error) {
                if (401 === error.response.status) {
                    this.$refs.confirm.show('Session Timeout', 'Your session has timed out, please log in again', { showCancel: false });
                } else {
                    this.$refs.confirm.show('Error', 'An unexpected error occurred, please try again', { showCancel: false });
                }
            },
            load(program) {
                this.cleanProgram = cloneDeep(program);
                this.program = cloneDeep(this.cleanProgram);
            },
            add() {
                let institute = [];
                if (this.userInstitutes !== '*') {
                    institute = cloneDeep(this.userInstitutes);
                }
                this.load({ institute, focus: [], audience: [], format: [], partners: [], funding: [], locations: [] });
            },
            del(program) {
                this.$refs.confirm.show('Delete Confirmation', `Are you sure you want to delete this initiative?`).then((confirm) => {
                    if (confirm) {
                        this.$api
                            .delete(`/delete/${program._id}`)
                            .then(() => {
                                this.search();
                            })
                            .catch(this.handleError);
                    }
                });
            },
            save() {
                this.$api
                    .post('/save', this.program)
                    .then((response) => {
                        this.load(response.data);
                    })
                    .catch(this.handleError);
            },
            undo() {
                this.program = cloneDeep(this.cleanProgram);
            },
            close() {
                this.search().finally(() => {
                    this.cleanProgram = null;
                    this.program = null;
                });
            },
            search() {
                return this.$api.get('/search', { params: { text: this.text } }).then((response) => {
                    this.programs = filter(response.data, (program) => {
                        if ('*' === this.userInstitutes) {
                            return true;
                        }
                        for (let institute of program.institute) {
                            if (includes(this.userInstitutes, institute)) {
                                return true;
                            }
                        }
                        return false;
                    });
                });
            },
            searchDebounced: debounce(function () {
                this.search();
            }, 500),
            editLocations() {
                this.locationsText = '';
                this.searchLocations().then(() => {
                    this.locationsDialog = true;
                });
            },
            hasLocation(location) {
                return !!find(this.program?.locations, { _id: location._id });
            },
            locationValid(location) {
                return location.name && location.address && location.city && location.state && location.zip && 5 === location.zip.length;
            },
            delLocation(location, index) {
                if (!location._id) {
                    this.locations.splice(index, 1);
                    return;
                }
                this.$refs.confirm.show('Delete Confirmation', `Are you sure you want to delete this location?`).then((confirm) => {
                    if (confirm) {
                        this.$api
                            .delete(`/locations/delete/${location._id}`)
                            .then(() => {
                                this.locations.splice(index, 1);
                            })
                            .catch(this.handleError);
                    }
                });
            },
            saveLocation(location, index) {
                this.$api
                    .post('/locations/save', location)
                    .then((response) => {
                        this.locations.splice(index, 1, Object.assign({ edit: false }, response.data));
                    })
                    .catch(this.handleError);
            },
            searchLocations() {
                return this.$api.get('/locations/search', { params: { text: this.locationsText } }).then((response) => {
                    this.locations = map(response.data, (location) => {
                        return Object.assign({ edit: false }, location);
                    });
                });
            },
            searchLocationsDebounced: debounce(function () {
                this.searchLocations();
            }, 500)
        }
    };
</script>

<style scoped>
    .append-outer-button {
        margin-top: -7px;
        margin-left: 20px;
    }
    ::v-deep thead th {
        white-space: nowrap;
    }
    ::v-deep .clickable-rows tbody tr {
        cursor: pointer;
    }
    ::v-deep .v-input--selection-controls {
        margin-top: 0;
        padding-top: 0;
    }

    .filter-list {
        max-height: 150px;
    }
</style>

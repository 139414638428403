<template>
    <v-app>
        <v-container class="header" fluid>
            <header role="banner">
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <div class="d-flex align-center">
                            <img :height="48" :src="require('@/assets/logo.png')" :alt="`Manufacturing USA | ${title} -- ${subtitle}`" />
                        </div>
                    </v-col>
                    <v-col class="text-right">
                        <nav class="mt-2" role="navigation" v-if="user">
                            <div class="d-flex align-center">
                                <v-spacer></v-spacer>
                                <v-btn elevation="0" :text="'search' !== view" color="primary" @click="view = 'search'">
                                    <v-icon left>mdi-magnify</v-icon>
                                    Search Initiatives
                                </v-btn>
                                <v-btn elevation="0" :text="'admin' !== view" color="primary" @click="view = 'admin'">
                                    <v-icon left>mdi-database-edit</v-icon>
                                    Add/Edit Initiatives
                                </v-btn>
                                <v-btn elevation="0" :text="'users' !== view" color="primary" @click="view = 'users'" v-if="user.isAdmin">
                                    <v-icon left>mdi-account-multiple</v-icon>
                                    Manage Users
                                </v-btn>
                                <v-divider vertical class="mx-3"></v-divider>
                                <v-btn text color="primary" @click="logout()">
                                    <v-icon left>mdi-logout</v-icon>
                                    Logout
                                </v-btn>
                            </div>
                        </nav>
                    </v-col>
                </v-row>
            </header>
        </v-container>
        <v-container fluid class="banner pa-0">
            <div role="region">
                <v-img :src="require('@/assets/banner.png')" :height="bannerHeight"></v-img>
                <div class="intro d-flex flex-column align-center justify-center" :style="cssVars">
                    <div class="intro-title">{{ title }}</div>
                    <div class="intro-subtitle">{{ subtitle }}</div>
                </div>
            </div>
        </v-container>
        <v-main>
            <v-fade-transition hide-on-leave>
                <router-view></router-view>
            </v-fade-transition>
        </v-main>
        <DefinitionsDialog ref="definitionsDialog"></DefinitionsDialog>
    </v-app>
</template>

<script>
    import DefinitionsDialog from '@/components/DefinitionsDialog';
    import { get, sync } from 'vuex-pathify';

    export default {
        name: 'App',
        components: { DefinitionsDialog },
        data: () => ({
            title: 'EWD CONNECT',
            subtitle: 'Education & Workforce Development Offerings and Partners Search',
            bannerHeight: 160
        }),
        computed: {
            view: sync('view'),
            user: get('user'),
            cssVars() {
                return {
                    '--banner-height': this.bannerHeight + 'px'
                };
            }
        },
        mounted() {
            this.$root.$definitionsDialog = this.$refs.definitionsDialog;
        },
        methods: {
            logout() {
                this.$store.dispatch('logout');
            }
        }
    };
</script>

<style lang="scss" scoped>
    .header {
        border-bottom: 6px solid #5e8fb8;
    }
    .banner {
        position: relative;
        .intro {
            position: absolute;
            top: 0;
            width: 100%;
            height: var(--banner-height);
            color: #ffffff;
            padding: 0 1.5em;
            text-align: center;
            .intro-title {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.75em;
            }
            .intro-subtitle {
                font-size: 1em;
            }
        }
    }
</style>

export const colors = {
    primary: '#306899',
    secondary: '#cb3133'
};

export const definition = (value) => {
    switch (value) {
        case 'K-12':
            return 'Students from kindergarten through high school.';
        case 'Undergraduate students':
            return 'Post-secondary students, including those attending technical schools, community colleges, and universities.';
        case 'Graduate students':
            return 'Students completing a degree beyond an associate or bachelor’s degree.';
        case 'Incumbent workforce':
            return 'Existing members of the manufacturing workforce and those with relevant skills that can transition to manufacturing-related careers. Also includes employers and company leadership.';
        case 'Educators':
            return 'All teachers, trainers, and instructors that educate and/or train the existing and emerging workforce.';
        case 'Veterans':
            return 'Former members of the military, naval, or air service.';
        case 'Under-resourced communities':
            return 'Groups that face social and/or economic barriers to participating in the manufacturing workforce, including economically disadvantaged populations and racial or ethnic minorities.';
        case 'Education or training program':
            return 'A series of related EWD courses or activities that build toward overall education/training objectives. <ul><li><u>Example</u>: A program to transition veterans into a smart manufacturing career.</li></ul>';
        case 'Course or workshop':
            return 'A standalone, interactive class, workshop, or bootcamp designed to teach a competency or skill. <ul><li><u>Example</u>: A one-day workshop teaching workers to use modeling software.</li></ul>';
        case 'Presentation or webinar':
            return 'One-way presentation of information (either in person or online) on a single topic. <ul><li><u>Example</u>: A recorded lecture on new advancements in the field of photonics.</li></ul>';
        case 'Certification or credential':
            return 'An EWD certification, certificate, or credential, including any pathways to achieving it. <ul><li><u>Example</u>: A certificate designating a trained CNC machinist.</li></ul>';
        case 'Internship':
            return 'Placement of a student within an organization to gain job experience. <ul><li><u>Example</u>: A program to place undergraduates in entry-level positions in a manufacturing plant.</li></ul>';
        case 'Apprenticeship':
            return 'An arrangement where a student learns skills and competencies directly from an experienced worker. <ul><li><u>Example</u>: A program that pairs an inexperienced worker with an expert welder.</li></ul>';
        case 'Event':
            return 'A planned EWD activity (that is not part of a program or course) that occurs on specific dates. <ul><li><u>Example</u>: A one, day, hands-on STEM competition for K-12 students.</li></ul>';
        case 'Resource':
            return 'A physical or digital tool that can be used for education and/or training purposes. <ul><li><u>Example</u>: A user facility where workers can practice testing materials, or an online bank of teaching aids for high school educators.</li></ul>';
        case 'Potential partner':
            return 'An organization that could potentially partner with the institutes on future EWD initiatives.';
    }
    return undefined;
};

export const audiences = ['K-12', 'Undergraduate students', 'Graduate students', 'Incumbent workforce', 'Educators', 'Veterans', 'Under-resourced communities'];

export const categories = ['Education or training program', 'Course or workshop', 'Presentation or webinar', 'Certification or credential', 'Internship', 'Apprenticeship', 'Event', 'Resource', 'Potential partner'];

export const formats = ['In person', 'Virtual', 'Hybrid', 'Other', 'TBD'];

export const statuses = ['In development', 'Active', 'Completed', 'TBD'];

export const institutes = ['AFFOA', 'AIM Photonics', 'ARM', 'America Makes', 'BioFabUSA', 'BioMADE', 'CESMII', 'CyManII', 'IACMI', 'LIFT', 'MxD', 'NIIMBL', 'NextFlex', 'PowerAmerica', 'RAPID', 'REMADE', 'Potential Partner'];

export const focuses = [
    'Additive Manufacturing',
    'Artificial Intelligence',
    'Automation',
    'Biofabrication',
    'Bioindustrial Manufacturing',
    'Biopharmaceuticals',
    'Biotechnology',
    'Chemical Processing',
    'Composites',
    'Cybersecurity',
    'Design',
    'Electronics',
    'Functional Fabrics',
    'Lightweighting',
    'Materials & Materials Processing',
    'Metrology',
    'Modeling & Simulation',
    'Optics & Photonics',
    'Process Intensification',
    'Recycling & Reuse',
    'Robotics',
    'Sensors',
    'Smart & Digital Manufacturing',
    'Sustainable Manufacturing'
];

export const states = [
    { code: 'AL', name: 'Alabama' },
    { code: 'AK', name: 'Alaska' },
    { code: 'AZ', name: 'Arizona' },
    { code: 'AR', name: 'Arkansas' },
    { code: 'CA', name: 'California' },
    { code: 'CO', name: 'Colorado' },
    { code: 'CT', name: 'Connecticut' },
    { code: 'DE', name: 'Delaware' },
    { code: 'DC', name: 'District of Columbia' },
    { code: 'FL', name: 'Florida' },
    { code: 'GA', name: 'Georgia' },
    { code: 'HI', name: 'Hawaii' },
    { code: 'ID', name: 'Idaho' },
    { code: 'IL', name: 'Illinois' },
    { code: 'IN', name: 'Indiana' },
    { code: 'IA', name: 'Iowa' },
    { code: 'KS', name: 'Kansas' },
    { code: 'KY', name: 'Kentucky' },
    { code: 'LA', name: 'Louisiana' },
    { code: 'ME', name: 'Maine' },
    { code: 'MD', name: 'Maryland' },
    { code: 'MA', name: 'Massachusetts' },
    { code: 'MI', name: 'Michigan' },
    { code: 'MN', name: 'Minnesota' },
    { code: 'MS', name: 'Mississippi' },
    { code: 'MO', name: 'Missouri' },
    { code: 'MT', name: 'Montana' },
    { code: 'NE', name: 'Nebraska' },
    { code: 'NV', name: 'Nevada' },
    { code: 'NH', name: 'New Hampshire' },
    { code: 'NJ', name: 'New Jersey' },
    { code: 'NM', name: 'New Mexico' },
    { code: 'NY', name: 'New York' },
    { code: 'NC', name: 'North Carolina' },
    { code: 'ND', name: 'North Dakota' },
    { code: 'OH', name: 'Ohio' },
    { code: 'OK', name: 'Oklahoma' },
    { code: 'OR', name: 'Oregon' },
    { code: 'PA', name: 'Pennsylvania' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'RI', name: 'Rhode Island' },
    { code: 'SC', name: 'South Carolina' },
    { code: 'SD', name: 'South Dakota' },
    { code: 'TN', name: 'Tennessee' },
    { code: 'TX', name: 'Texas' },
    { code: 'UT', name: 'Utah' },
    { code: 'VT', name: 'Vermont' },
    { code: 'VA', name: 'Virginia' },
    { code: 'WA', name: 'Washington' },
    { code: 'WV', name: 'West Virginia' },
    { code: 'WI', name: 'Wisconsin' },
    { code: 'WY', name: 'Wyoming' }
];

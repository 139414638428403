<template>
    <v-dialog v-model="dialog" :width="600" persistent scrollable>
        <v-card title="Definitions" role="dialog">
            <div class="d-flex align-center py-2 pl-4 pr-3 primary white--text">
                <v-icon left color="white">mdi-help-circle-outline</v-icon>
                <div class="title">Definitions</div>
                <v-spacer></v-spacer>
                <v-btn icon aria-label="close" @click="close()">
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </div>
            <v-card-text class="body-2 px-6" tabindex="0">
                <div class="my-6">
                    <div class="title">Audience</div>
                    <v-row no-gutters class="my-3" align="start" v-for="(audience, index) in audiences" :key="index">
                        <v-col>
                            <span class="primary--text font-weight-bold">{{ audience }}:</span>
                            <span class="ml-1" v-html="definition(audience)"></span>
                        </v-col>
                    </v-row>
                </div>
                <div class="my-6">
                    <div class="title">Category</div>
                    <v-row no-gutters class="my-3" align="start" v-for="(category, index) in categories" :key="index">
                        <v-col>
                            <span class="primary--text font-weight-bold">{{ category }}:</span>
                            <span class="ml-1" v-html="definition(category)"></span>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import { definition, audiences, categories } from '@/common';

    export default {
        data: () => ({
            audiences,
            categories,
            dialog: false
        }),
        methods: {
            definition,
            show() {
                this.dialog = true;
            },
            close() {
                this.dialog = false;
            }
        }
    };
</script>

import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import App from './App.vue';
import './filters';
import store from './store';
import router from './router';
import api from './api';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.prototype.$api = api;

Vue.use(VueGoogleMaps);
Vue.component('GmapCluster', GmapCluster);

async function init() {
    try {
        await store.dispatch('init');
    } catch (err) {
        // do nothing
    }

    new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App)
    }).$mount('#app');
}
init().then(() => {});

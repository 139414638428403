<template>
    <tr>
        <th v-for="(header, index) in headers" :key="index" :class="{ sortable: header.sortable }" class="grey--text text--darken-3" @click="sort(header)">
            <span>{{ header.text }}</span>
            <v-icon small :class="['sort-icon', header.sort, { 'primary--text': header.sort }]" v-if="header.sortable">mdi-arrow-up</v-icon>
        </th>
    </tr>
</template>

<script>
    import { find } from 'lodash';

    export default {
        props: {
            headers: {
                type: Array,
                required: true
            }
        },
        computed: {
            sortColumn() {
                return find(this.headers, { sortable: true, sort: 'asc' }) || find(this.headers, { sortable: true, sort: 'desc' }) || {};
            }
        },
        methods: {
            sort(header) {
                if (header.sortable) {
                    if (this.sortColumn.value === header.value) {
                        header.sort = 'asc' === header.sort ? 'desc' : null;
                    } else {
                        this.sortColumn.sort = null;
                        header.sort = 'asc';
                    }
                }
            }
        }
    };
</script>

<style scoped>
    .sortable {
        cursor: pointer;
    }
    .sort-icon {
        opacity: 0;
    }
    .asc,
    .desc,
    .sortable:hover .sort-icon {
        opacity: 1;
        transition: all 0.3s;
    }
    .desc {
        transform: rotate(-180deg);
    }
</style>

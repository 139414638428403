<template>
    <v-simple-table class="results">
        <template v-slot:default>
            <thead>
                <tr is="sortable-table-header" :headers="headers"></tr>
            </thead>
            <tbody>
                <template v-for="(program, index) in sortedPrograms">
                    <tr :key="`${index}:${program._id}`" :class="['summary', { expanded: program.isExpanded }, { 'potential-partner': 'Potential Partner' === program.institute[0] }]" @click="program.isExpanded = !program.isExpanded">
                        <td>
                            <v-fab-transition hide-on-leave>
                                <v-btn icon aria-label="Show program" v-if="!program.isExpanded">
                                    <v-icon color="primary">mdi-plus</v-icon>
                                </v-btn>
                            </v-fab-transition>
                            <v-fab-transition hide-on-leave>
                                <v-btn icon aria-label="Hide program" v-if="program.isExpanded">
                                    <v-icon color="primary">mdi-minus</v-icon>
                                </v-btn>
                            </v-fab-transition>
                        </td>
                        <td>{{ program.institute.join(', ') }}</td>
                        <td>{{ program.name }}</td>
                        <td>{{ program.location.short }}</td>
                        <td>{{ program.category }}</td>
                    </tr>
                    <tr :key="index" class="details" v-if="program.isExpanded">
                        <td :colspan="headers.length" class="pa-4">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="2" class="font-weight-bold text-right primary--text">Technology Focus Area(s)</v-col>
                                    <v-col>
                                        <!-- <div v-for="(focus, index) in program.focus" :key="index">{{ focus }}</div> -->
                                        <div>{{ program.focus.join(', ') }}</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2" class="font-weight-bold text-right primary--text">Audience(s)</v-col>
                                    <v-col>
                                        <!-- <div v-for="(audience, index) in program.audience" :key="index">{{ audience }}</div> -->
                                        <div>{{ program.audience.join(', ') }}</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2" class="font-weight-bold text-right primary--text">Status</v-col>
                                    <v-col>
                                        <div>{{ program.status }}</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2" class="font-weight-bold text-right primary--text">Format</v-col>
                                    <v-col>
                                        <!-- <div v-for="(format, index) in program.format" :key="index">{{ format }}</div> -->
                                        <div>{{ program.format.join(', ') }}</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2" class="font-weight-bold text-right primary--text">Partners</v-col>
                                    <v-col>
                                        <div v-for="(partner, index) in program.partners" :key="index">{{ partner }}</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2" class="font-weight-bold text-right primary--text">Service Locations</v-col>
                                    <v-col>
                                        <div v-for="(location, index) in program.locations" :key="index">{{ location.name }} ({{ location.short }})</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2" class="font-weight-bold text-right primary--text">Funding Source(s)</v-col>
                                    <v-col>
                                        <!-- <div v-for="(funding, index) in program.funding" :key="index">{{ funding }}</div> -->
                                        <div>{{ program.funding.join(', ') }}</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2" class="font-weight-bold text-right primary--text">Description</v-col>
                                    <v-col>
                                        <div>{{ program.description }}</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2" class="font-weight-bold text-right primary--text">Website</v-col>
                                    <v-col>
                                        <div>
                                            <a :href="program.url" target="_blank">{{ program.url }}</a>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                </template>
            </tbody>
            <tfoot v-if="!programs.length">
                <tr>
                    <td :colspan="headers.length">
                        <em>Select search criteria above to see matching results</em>
                    </td>
                </tr>
            </tfoot>
        </template>
    </v-simple-table>
</template>

<script>
    import { find, reverse, sortBy, toUpper } from 'lodash';
    import SortableTableHeader from '@/components/SortableTableHeader';

    export default {
        components: { SortableTableHeader },
        data: () => ({
            headers: [
                { text: '', sortable: false, sort: null },
                { text: 'Institute Affiliation', value: 'institute', sortable: true, sort: null },
                { text: 'Name / Title', value: 'name', sortable: true, sort: null },
                { text: 'Service Location', value: 'location', sortable: true, sort: null },
                { text: 'Category', value: 'category', sortable: true, sort: null }
            ]
        }),
        props: {
            programs: {
                type: Array,
                required: true
            }
        },
        computed: {
            sortColumn() {
                return find(this.headers, { sortable: true, sort: 'asc' }) || find(this.headers, { sortable: true, sort: 'desc' });
            },
            sortedPrograms() {
                if (this.sortColumn) {
                    const sorted = (() => {
                        return sortBy(this.programs, (program) => {
                            return toUpper(program[this.sortColumn.value] || '');
                        });
                    })();
                    if ('desc' === this.sortColumn.sort) {
                        return reverse(sorted);
                    }
                    return sorted;
                }
                return this.programs;
            }
        }
    };
</script>

<style lang="scss" scoped>
    $table-border: thin solid #808080;
    $table-spacing: 10px;

    .results tbody tr.summary {
        cursor: pointer;
    }
    .results tbody tr.summary.potential-partner td {
        background-color: rgba(128, 128, 128, 0.12);
    }
    .results tbody tr.summary.expanded td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
    .results tbody tr.details:hover {
        background: none !important;
    }
    .results tbody tr.details td {
        position: relative;
        top: -#{$table-spacing};
        border-top: none !important;
        white-space: pre-wrap;
    }
    .results tbody tr.details ::v-deep .v-chip:hover::before {
        opacity: 0;
    }
    .results ::v-deep table {
        border-spacing: 0 $table-spacing !important;
        position: relative;
        top: -#{$table-spacing * 2};
    }
    .results thead ::v-deep th {
        font-family: $heading-font-family;
        text-transform: uppercase;
        white-space: nowrap !important;
        opacity: 0.75;
        border: none !important;
    }
    .results thead ::v-deep th * {
        position: relative;
        top: #{$table-spacing * 2};
    }
    .results tbody td {
        border-top: $table-border !important;
        border-bottom: $table-border !important;
        padding: 8px 16px;
    }
    .results tbody td:first-child {
        border-left: $table-border !important;
    }
    .results tbody td:last-child {
        border-right: $table-border !important;
    }
    .results tfoot td {
        border: none !important;
    }
</style>

import store from './store';
import axios from 'axios';
import { includes } from 'lodash';

axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

const api = axios.create({ baseURL: '/api' });
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (includes([401, 403], error.response.status)) {
            store.dispatch('reset');
        }
        return Promise.reject(error);
    }
);

export default api;

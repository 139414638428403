import Vue from 'vue';
import Vuex from 'vuex';

import api from './api';
import pathify from './pathify';
import { make } from 'vuex-pathify';
import { compact, each, flatten, filter, includes, map } from 'lodash';

import { loadGmapApi, gmapApi } from 'vue2-google-maps';

import { audiences, categories, focuses, states } from '@/common';

Vue.use(Vuex);

const queryParams = (url) => {
    const index = url.indexOf('?');
    if (index > -1) {
        return url.substring(index + 1);
    }
    return url;
};

let gmapApiLoaded = false;

const state = {
    loading: 0,
    view: 'search',
    programs: [],
    filters: {
        show: true,
        text: '',
        selectedAudiencesIndexes: [],
        selectedFocusesIndexes: [],
        selectedStatesIndexes: [],
        selectedCategoriesIndexes: [],
        selectedLocation: null
    },
    key: null,
    user: null
};

const getters = {
    ...make.getters(state),
    locations(state) {
        const locations = {};
        each(state.programs, (program) => {
            if (!locations[program.location._id]) {
                locations[program.location._id] = Object.assign({ _programs: [] }, program.location);
            }
            locations[program.location._id]._programs.push(program);
            if ('Potential Partner' === program.institute[0]) {
                locations[program.location._id].hasPotentialPartners = true;
            }
        });
        const google = gmapApi();
        return compact(
            map(locations, (location) => {
                const latLng = google && location.location && location.location.coordinates && 2 === location.location.coordinates.length && new google.maps.LatLng(location.location.coordinates[1], location.location.coordinates[0]);
                if (!latLng) {
                    return null;
                }
                return Object.assign({ count: location._programs.length, latLng }, location);
            })
        );
    },
    filteredPrograms(state) {
        if (state.filters.selectedLocation) {
            return filter(state.programs, (program) => program.location._id === state.filters.selectedLocation._id);
        }
        return state.programs;
    },
    selectedAudiences(state) {
        return map(state.filters.selectedAudiencesIndexes, (index) => {
            return audiences[index];
        });
    },
    selectedFocuses(state) {
        return map(state.filters.selectedFocusesIndexes, (index) => {
            return focuses[index];
        });
    },
    selectedStates(state) {
        return map(state.filters.selectedStatesIndexes, (index) => {
            return states[index].code;
        });
    },
    selectedCategories(state) {
        return map(state.filters.selectedCategoriesIndexes, (index) => {
            return categories[index];
        });
    },
    hasActiveFilters(state, getters) {
        return state.filters.text?.length || getters.selectedAudiences.length || getters.selectedFocuses.length || getters.selectedStates.length || getters.selectedCategories.length;
    },
    userInstitutes(state) {
        if (state.user) {
            if (includes(state.user.roles, 'admin')) {
                return '*';
            } else {
                return compact(
                    map(state.user.roles, (role) => {
                        if (/^edit:/.test(role)) {
                            return role.replace(/^edit:/, '');
                        }
                        return null;
                    })
                );
            }
        }
        return null;
    }
};
const mutations = {
    ...make.mutations(state),
    resetFilters(state, show = false) {
        if (show) {
            state.filters.show = true;
        }
        state.filters.text = '';
        state.filters.selectedAudiencesIndexes = [];
        state.filters.selectedFocusesIndexes = [];
        state.filters.selectedStatesIndexes = [];
        state.filters.selectedCategoriesIndexes = [];
        state.filters.selectedLocation = null;
    },
    resetSelectedLocation(state) {
        state.filters.selectedLocation = null;
    },
    incrementLoading(state) {
        state.loading++;
    },
    decrementLoading(state) {
        state.loading--;
    }
};
const actions = {
    ...make.actions(state),
    init({ dispatch }) {
        return Promise.all([dispatch('login')]);
    },
    reset({ commit }) {
        commit('view', 'search');
        commit('programs', []);
        commit('resetFilters');
        commit('key', null);
        commit('user', null);
    },
    key({ commit }) {
        commit('incrementLoading');
        return api
            .get('/key')
            .then((response) => {
                const key = response.data.key;
                commit('key', key);
                if (!gmapApiLoaded) {
                    gmapApiLoaded = true;
                    loadGmapApi({ key });
                }
            })
            .finally(() => {
                commit('decrementLoading');
            });
    },
    search({ commit, state, getters }) {
        const params = {
            text: state.filters.text,
            audience: getters.selectedAudiences,
            focus: getters.selectedFocuses,
            state: getters.selectedStates,
            category: getters.selectedCategories
        };
        commit('resetSelectedLocation', null);
        commit('incrementLoading');
        return api
            .get('/search', { params })
            .then((response) => {
                const isExpanded = /expand/i.test(queryParams(window.location.href));
                commit(
                    'programs',
                    flatten(
                        map(response.data, (program) => {
                            return map(program.locations, (location) => {
                                return Object.assign({ location, isExpanded }, program);
                            });
                        })
                    )
                );
            })
            .finally(() => {
                commit('decrementLoading');
            });
    },
    login({ commit, dispatch }) {
        commit('incrementLoading');
        return api
            .get('/login')
            .then((response) => {
                commit('user', response.data);
                dispatch('key');
            })
            .finally(() => {
                commit('decrementLoading');
            });
    },
    logout({ commit, dispatch }) {
        commit('incrementLoading');
        return api
            .get('/logout')
            .then(() => {
                dispatch('reset');
            })
            .finally(() => {
                commit('decrementLoading');
            });
    }
};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    plugins: [pathify.plugin]
});

<template>
    <v-dialog v-model="dialog" :max-width="options.width" persistent>
        <v-card>
            <v-toolbar dense dark color="primary" v-if="title">
                <v-icon left v-if="options.titleIcon">{{ options.titleIcon }}</v-icon>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="body-1 pa-5">{{ message }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()">
                    <v-icon left v-if="options.okIcon">{{ options.okIcon }}</v-icon>
                    {{ options.okText }}
                </v-btn>
                <v-btn text @click="cancel()" v-if="options.showCancel">
                    <v-icon left v-if="options.cancelIcon">{{ options.cancelIcon }}</v-icon>
                    {{ options.cancelText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data: () => ({
            dialog: false,
            resolve: null,
            reject: null,
            title: null,
            message: null,
            options: {
                width: 400,
                titleIcon: 'mdi-alert',
                okText: 'Ok',
                okIcon: 'mdi-check',
                showCancel: true,
                cancelText: 'Cancel',
                cancelIcon: 'mdi-close'
            }
        }),
        methods: {
            show(title, message, options) {
                this.dialog = true;
                this.title = title;
                this.message = message;
                this.options = Object.assign(this.options, options);
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            ok() {
                this.resolve(true);
                this.dialog = false;
            },
            cancel() {
                this.resolve(false);
                this.dialog = false;
            }
        }
    };
</script>
